import { computed, reactive, Ref, ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { GridLayoutType } from '@/constants';
import { Participant } from 'livekit-client';

type WidgetName = 'chat' | 'members' | 'analytics' | 'mascot';

export const useLayoutStore = defineStore('layout', () => {
    const isLoadingScreenShown = ref(false);
    const gridType = ref(GridLayoutType.default);
    const focusParticipant = ref(null) as Ref<Participant | null>;
    const widgets = reactive<Record<WidgetName, boolean>>({
        chat: false,
        members: false,
        analytics: false,
        mascot: false,
    });

    const hasAnyWidget = computed(() => Object.values(widgets).some(Boolean));

    const closeAllWidgets = () => {
        (Object.keys(widgets) as WidgetName[]).forEach(
            (key) => (widgets[key] = false),
        );
    };

    const toggleWidget = (key: WidgetName, state?: boolean) => {
        const newState = state ?? !widgets[key];

        closeAllWidgets();
        widgets[key] = newState;
    };

    const toggleLoadingScreen = (state?: boolean) => {
        isLoadingScreenShown.value = state ?? !isLoadingScreenShown.value;
    };

    const setGridType = (type: GridLayoutType) => {
        gridType.value = type;
    };

    const setFocusParticipant = (participant: Participant | null) => {
        focusParticipant.value = participant;
    };

    return {
        isLoadingScreenShown,
        widgets,
        hasAnyWidget,
        gridType,
        focusParticipant,
        toggleWidget,
        closeAllWidgets,
        toggleLoadingScreen,
        setGridType,
        setFocusParticipant,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot));
}
